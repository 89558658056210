/** @jsx jsx */
import { jsx } from "theme-ui";
import { DefaultButton } from "office-ui-fabric-react";
import AbhyasiEntity from "gatsby-plugin-hfn-profile/components/SrcmEntities/AbhyasiEntity";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";

const allowedUsers = require("../../components/PreceptorSearch/allowed-users.json");

const WelcomeAuthorized = () => {
  const { srcmProfile } = useAuth();
  return (
    <div>
      <h5>Welcome, {srcmProfile?.name}</h5>

      <div>
        <table sx={{ border: "solid 1px #DDD", p: 1 }}>
          <tbody>
            <tr>
              <td colSpan={2} sx={{ pb: 2 }}>
                <div>Search Application</div>
                <DefaultButton
                  iconProps={{ iconName: "Forward" }}
                  sx={{ m: 2 }}
                  href="/preceptor-mgmt/search"
                  text="Open"
                />

                <DefaultButton
                  iconProps={{ iconName: "Forward" }}
                  sx={{ m: 2 }}
                  href="/preceptor-mgmt/search/bulk"
                  text="Bulk Search"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div>Allowed Users</div>
              </td>
            </tr>
            {allowedUsers.map((ref) => (
              <tr key={ref}>
                <td>
                  <AbhyasiEntity abhyasiId={ref} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WelcomeAuthorized;
